import SAIcon from "../assets/image/sa-icon.png";

/**
 * This is a functional componant for Loading
 * @returns JSX for Loading
 */
export const SALoader = () => {
  return (
    <div className="sa-loading-spinner">
      <img src={SAIcon} alt="Loader" className="sa-spinner-image" />
    </div>
  );
};
