import { operation } from "retry";

/**
 * Function to call api with retry logic
 */
export const retryApiCall = (apiFunction, config, retries = 3, delay = 2000) => {
  return new Promise((resolve, reject) => {
    const op = operation({
      retries,
      factor: 1, // Fixed for no exponential backoff
      minTimeout: delay,
      maxTimeout: delay,
    });

    op.attempt(async (currentAttempt) => {
      try {
        const response = await apiFunction(config);
        resolve(response);
      } catch (err) {
        if (err?.response && err?.response?.status === 500) {
          console.log(`Retry attempt ${currentAttempt} failed with status 500.`);
          if (op.retry(err)) return; // Retry on 500 errors
        }
        reject(err); // Reject if not retryable or retries are exhausted
      }
    });
  });
};
